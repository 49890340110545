<template>
  <!-- 合同物流 - 物流计划  -->
  <div class="contractWaybillPage" v-loading="flag.loading">
    <div class="facilityBox">
      <!-- tab切换 -->
      <el-tabs v-model="flag.activeTab" @tab-click="changeTabs">
        <el-tab-pane label="服务商承运列表" name="1"></el-tab-pane>
        <el-tab-pane label="自有运力承运列表" name="0"></el-tab-pane>
        <el-tab-pane label="司机抢单列表" name="2"></el-tab-pane>
      </el-tabs>

      <!-- 搜索条件 -->
      <el-form ref="supplierForm" inline :model="supplierForm">
        <el-form-item label="运单状态：" label-width="100px">
          <el-select placeholder="请选择运单状态" v-model="supplierForm.status" style="width: 160px" :key="flag.activeTab">
            <el-option v-for="item in flag.activeTab != 2 ? orderStatusList : seizeOrderStatusList" :key="item.value"
              :label="item.name" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="运单号：" label-width="100px">
          <el-input v-model="supplierForm.orderID" placeholder="请输入运单号" style="width: 160px" clearable></el-input>
        </el-form-item>

        <el-form-item label="姓名：" prop="keyword" label-width="100px">
          <el-input style="width: 300px" v-model="supplierForm[nameSelect]" placeholder="请输入姓名" clearable>
            <el-select style="width: 120px" slot="prepend" placeholder="选择姓名类型" v-model="nameSelect"
              @change="chooseType($event, nameTypeList, nameSelect)">
              <el-option v-for="item in flag.activeTab != 2 ? nameTypeList : seizeNameTypeList" :key="item.name"
                :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-form-item label="手机号：" prop="keyword" label-width="100px">
          <el-input style="width: 300px" v-model="supplierForm[phoneSelect]" placeholder="请输入姓名" clearable>
            <el-select style="width: 120px" slot="prepend" placeholder="选择号码类型" v-model="phoneSelect"
              @change="chooseType($event, phoneTypeList, phoneSelect)">
              <el-option v-for="item in flag.activeTab != 2 ? phoneTypeList : seizePhoneTypeList" :key="item.name"
                :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <el-button type="primary" @click="(pagination.page = 1), getOrderHtwList(orderFrom)"
          icon="el-icon-search">搜索</el-button>
        <el-button type="primary" @click="clearSearch" icon="el-icon-delete">清空</el-button>
        <el-button type="primary" :icon="showSelect ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
          @click="isShowSelect">更多选项</el-button>
        <br />

        <el-collapse-transition>
          <div style="display: flex; flex-wrap: wrap" v-show="showSelect">
            <el-form-item label="货品名称：" label-width="100px">
              <el-input v-model="supplierForm.goodsName" style="width: 160px" placeholder="请输入货品名称" clearable></el-input>
            </el-form-item>

            <el-form-item v-if="flag.activeTab == 1" label="服务商名称：" label-width="100px">
              <el-input v-model="supplierForm.facilitatorName" style="width: 160px" placeholder="请输入服务商名称"
                clearable></el-input>
            </el-form-item>

            <el-form-item label="地址：" prop="keyword" label-width="100px">
              <el-input style="width: 300px" v-model="supplierForm[addressSelect]" placeholder="请输入姓名" clearable>
                <el-select style="width: 120px" v-model="addressSelect" slot="prepend" placeholder="选择地址类型"
                  @change="chooseType($event, addressTypeList, addressSelect)">
                  <el-option v-for="item in addressTypeList" :key="item.name" :label="item.name"
                    :value="item.value"></el-option>
                </el-select>
              </el-input>
            </el-form-item>

            <el-form-item>
              <div style="display: flex">
                <el-dropdown @command="handleCommand" trigger="click" placement="bottom">
                  <span class="el-dropdown-link">
                    <div style="
                        width: 108px;
                        text-align: right;
                        padding-right: 12px;
                        color: #606266;
                        cursor: pointer;
                        white-space: nowrap;
                      ">
                      {{ timeSelectTxt
                      }}<i style="margin-left: 2px" class="el-icon-arrow-down">：</i>
                    </div>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="item" v-for="item in timeTypeList" :key="item.name">{{ item.name }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-date-picker v-model="supplierForm[timeSelect]" type="daterange" start-placeholder="请选择开始时间"
                  end-placeholder="请选择结束时间" value-format="yyyy-MM-dd" @change="changeTime">
                </el-date-picker>
              </div>
            </el-form-item>
          </div>
        </el-collapse-transition>

        <el-button type="primary" :icon="flag.toOrderLibrary ? 'el-icon-loading' : 'el-icon-s-promotion'"
          style="margin-left: 20px" @click="toOrderLibrary">发往运单库</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="createOrder('')">创建计划</el-button>
      </el-form>

      <el-divider content-position="left"></el-divider>

      <!-- 表格 -->
      <el-table ref="multipleTable" :data="pagination.tableData" tooltip-effect="dark" style="width: 100%"
        @selection-change="handleSelectionChange" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        :key="flag.activeTab" v-loading="pagination.loading" :row-key="getRowKeys">
        <el-table-column fixed="left" type="selection" :selectable="selectable" width="50" align="center"
          :reserve-selection="true">
        </el-table-column>
        <el-table-column prop="index" fixed="left" label="序号" width="50" align="center">
        </el-table-column>
        <el-table-column prop="OrderID" label="运单号" width="200" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column v-if="flag.activeTab == 1" prop="FacilitatorName" label="服务商名称" width="200" align="center"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="LoaderName" label="提货人" width="100" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <el-link class="clickTxt" @click="orderMoreTime(scope.row.OrderID, 'name')">
              {{ scope.row.LoaderName }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column prop="SignUserName" label="收货人" show-overflow-tooltip width="100" align="center">
          <template slot-scope="scope">
            <el-link class="clickTxt" @click="orderMoreTime(scope.row.OrderID, 'name')">
              {{ scope.row.SignUserName }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column prop="StartAdressRoad" label="起始地" width="210" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span> {{ scope.row.StartAdressRoad }}</span>
            <!-- <el-link class="clickTxt" @click="
              flag.activeTab != 2 ? openMap(scope.row) : showSeizeDriverInfo(scope.row)
              ">
              {{ scope.row.StartAdressRoad }}
            </el-link> -->
          </template>
        </el-table-column>
        <el-table-column prop="EndAddressRoad" label="到达地" width="210" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.EndAddressRoad }}</span>
            <!-- <el-link class="clickTxt" @click="
              flag.activeTab != 2 ? openMap(scope.row) : showSeizeDriverInfo(scope.row)
              ">
              {{ scope.row.EndAddressRoad }}
            </el-link> -->
          </template>
        </el-table-column>
        <el-table-column prop="GoodsName" label="货品名称" width="130" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <el-link class="clickTxt" @click="orderMoreTime(scope.row.OrderID, 'goods')">
              {{ scope.row.GoodsName }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column prop="CarrierPrice" :label="flag.activeTab == 2 ? '单价' : '运费'" show-overflow-tooltip width="100"
          align="center">
          <template slot-scope="scope">
            <el-link class="clickTxt" @click="openUpdateFreightDialog(scope.row)"
              v-if="flag.activeTab == 0 && scope.row.Status < 13">
              {{ scope.row.CarrierPrice | formatMoney }}
            </el-link>

            <span style="color: #ff8b17" v-else>
              {{ scope.row.CarrierPrice | formatMoney }}
            </span>
          </template>
        </el-table-column>
        <el-table-column v-if="flag.activeTab == 2" prop="LoadCount" label="运输数量" show-overflow-tooltip width="100"
          align="center">
        </el-table-column>
        <el-table-column v-if="flag.activeTab == 2" prop="TranNum" label="剩余数量" show-overflow-tooltip width="100"
          align="center">
          <template slot-scope="scope">
            {{ residueNum(+scope.row.LoadCount, +scope.row.TranNum) }}
          </template>
        </el-table-column>
        <el-table-column v-if="flag.activeTab == 0" prop="PayeeName" label="收款人" width="100" show-overflow-tooltip
          align="center">
          <template slot-scope="scope">
            <el-link class="clickTxt" @click="getPersonInfo(scope.row, scope.row.PayeeId)">
              {{ scope.row.PayeeName }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column prop="StatusChangeTime" :label="flag.activeTab != 2 ? '状态变更时间' : '创建时间'" width="150"
          show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <el-link class="clickTxt" @click="orderMoreTime(scope.row.OrderID, 'time')">
              {{
                flag.activeTab != 2 ? scope.row.StatusChangeTime : scope.row.CreateTime
              }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column prop="Status" label="运单状态" width="100" fixed="right" show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <el-tag :color="setOrderStatus(scope.row.StatusName).bg" :style="[
              { color: setOrderStatus(scope.row.StatusName).text },
              { border: '0px' },
            ]">
              {{ setOrderStatus(scope.row.StatusName).name }}
            </el-tag>

            <el-popover placement="left" title="原因" trigger="hover" :content="scope.row.StatusChangeMsg">
              <i v-show="scope.row.StatusChangeMsg" class="el-icon-question"
                style="font-size: 16px; color: #ccc; cursor: pointer; margin-left: 4px" slot="reference"></i>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column v-if="flag.activeTab != 2" prop="DriverName" label="司机姓名" width="100" show-overflow-tooltip
          align="center">
          <template slot-scope="scope">
            <el-link :class="[
              { clickTxt: scope.row.DriverName != '待指派' },
              { linkActive: scope.row.DriverName == '待指派' },
            ]" @click="getPersonInfo(scope.row, scope.row.DriverId)"
              :underline="scope.row.DriverName != '待指派' ? true : false">
              {{ scope.row.DriverName }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column v-if="flag.activeTab != 2" prop="CarNumber" label="车牌号码" width="100" show-overflow-tooltip
          align="center">
          <template slot-scope="scope">
            <el-link :class="[
              { clickTxt: scope.row.CarNumber != '待指派' },
              { linkActive: scope.row.CarNumber == '待指派' },
            ]" @click="showVehicleInfo(scope.row.CarNumber)" :underline="scope.row.CarNumber != '待指派' ? true : false">
              {{ scope.row.CarNumber }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column v-if="flag.activeTab == 2" prop="DriverCount" label="接单司机" width="100" align="center">
          <template slot-scope="scope">
            <el-link :class="[
              { clickTxt: scope.row.DriverCount != 0 },
              { linkActive: scope.row.DriverCount == 0 },
            ]" @click="showSeizeDriverInfo(scope.row)" :underline="scope.row.DriverCount != 0 ? true : false">
              {{ scope.row.DriverCount }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column fixed="right" align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button v-if="scope.row.Status < 5 ||
              (scope.row.OrderType == 2 && scope.row.CarNumberCount == 0)
              " type="text" size="small" @click="createOrder(scope.row.OrderID)"
              style="color: #ff8b17">修改计划</el-button>
            <el-button v-if="scope.row.Status < 11 && scope.row.Status != 1" type="text" size="small"
              @click="cancelOrder(scope.row.OrderID)" style="color: #999999">取消计划</el-button>
            <el-button v-if="scope.row.Status == 1 ||
              (scope.row.OrderType == 2 && scope.row.CarNumberCount == 0)
              " type="text" size="small" @click="deleteOrder(scope.row.OrderID)" style="color: #f56c6c">删除</el-button>
            <el-button v-if="scope.row.Status >= 11" @click="$refs.htwlPdfPrint.init(scope.row.OrderID)" type="text"
              size="small">
              运费
            </el-button>
            <el-button v-if="scope.row.Status > 10" type="text" size="small" @click="
              flag.activeTab != 2
                ? detailOrder(scope.row)
                : showSeizeDriverInfo(scope.row)
              ">详情</el-button>
            <el-button v-if="scope.row.Status == 5" type="text" size="small" style="color: #ff8b17"
              @click="createOrder(scope.row.OrderID, true)">重选服务商</el-button>
            <el-button v-if="scope.row.Status == 8 && !scope.row.FacilitatorID" type="text" size="small"
              style="color: #ff8b17" @click="createOrder(scope.row.OrderID, false, true)">重选司机</el-button>
            <el-button v-if="scope.row.Status == 12 && flag.activeTab == 0" type="text" size="small"
              style="color: #ff8b17" @click="openPaymentDialog(scope.row)">支付运费</el-button>

            <el-button v-if="scope.row.Status > 10" type="text" size="small"
              @click="openEvidenceDialog(scope.row.OrderID)">凭证</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination class="pagination" background @current-change="handleCurrentChange" @size-change="sizeChange"
        :page-sizes="[10, 50, 100, 200]" :current-page.sync="pagination.page" :page-size="pagination.pagesize"
        layout="total,sizes, prev, pager, next, jumper" :total="pagination.total">
      </el-pagination>

      <!--人员信息弹框-->
      <el-dialog top="13vh" class="dialog" :visible.sync="flag.personDialog" width="1300px" :close-on-click-modal="false">
        <span slot="title">人员信息</span>
        <DriverInfo :driverInfo="driverInfo" />
      </el-dialog>

      <!--显示车辆信息弹框-->
      <el-dialog top="13vh" class="dialog" :visible.sync="flag.vehicleDialog" width="1300px"
        :close-on-click-modal="false">
        <span slot="title">车辆信息</span>
        <VehicleInfo :vehicleInfo="vehicleInfo" />
      </el-dialog>

      <!--查看轨迹-->
      <el-dialog width="800px" :visible.sync="flag.traceDialog" append-to-body class="deep_dialog"
        :close-on-click-modal="false">
        <span slot="title">查看地图</span>
        <!-- 地图组件 -->
        <TXmap style="height: 600px" v-if="flag.traceDialog" ref="map" :list="mapList">
        </TXmap>
      </el-dialog>

      <!-- 人员、商品信息查看dialog -->
      <el-dialog :title="otherDetail == 'name'
        ? '人员信息查看'
        : otherDetail == 'goods'
          ? '货品信息查看'
          : '更多时间查看'
        " :visible.sync="flag.peoAndGoodsDialog" width="700px" class="peoAndGoodsDialog" :close-on-click-modal="false">
        <div class="detail-msg">
          <el-form inline label-position="top" label-width="200px" :key="1">
            <el-form-item v-for="item in getOtherList" :key="item.name" :label="item.text">
              <el-input disabled class="inputWidth" :value="item.time">
                <template v-if="item.Company" slot="append">{{ item.Company }}</template>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>

      <!-- 订单完成 - 车辆轨迹 -->
      <el-dialog :visible.sync="flag.vehicleTrack" width="1200px" destroy-on-close custom-class="customClass"
        :close-on-click-modal="false">
        <vehicleTrack ref="vehicleTrack" :currentOrder="currentOrder" :orderDetail="currentOrderObj"></vehicleTrack>
      </el-dialog>

      <!-- 抢单查看司机 -  车辆轨迹  -->
      <el-dialog title="抢单司机信息" :visible.sync="flag.seizeDriverDialog" width="1320px" class="peoAndGoodsDialog"
        :close-on-click-modal="false">
        <div class="seize-detail">
          <el-form inline label-position="top" label-width="150px" :key="1">
            <div class="seize-detail-item">
              <el-form-item label="起始地">
                <el-input disabled class="inputWidth" :value="seizeDriverInfo.startDetailedAddress">
                  ></el-input>
              </el-form-item>
              <el-form-item label="到达地">
                <el-input disabled class="inputWidth" :value="seizeDriverInfo.endDetailedAddress">
                  ></el-input>
              </el-form-item>
              <el-form-item label="装货地址">
                <el-input disabled class="inputWidth" :value="seizeDriverInfo.upAddress">
                  ></el-input>
              </el-form-item>
              <el-form-item label="卸货地址">
                <el-input disabled class="inputWidth" :value="seizeDriverInfo.unAddress">
                  ></el-input>
              </el-form-item>
            </div>

            <!-- 表格 -->
            <el-table ref="multipleTable" :data="seizeDriverInfo.driverOrders" tooltip-effect="dark" style="width: 100%"
              height="350" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="pagination.loading"
              :row-key="(row) => row.Index">
              <el-table-column prop="Index" fixed="left" label="序号" align="center">
                <template slot-scope="scope">
                  {{ scope.row.Index + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="DriverName" label="司机姓名" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <el-link class="clickTxt" @click="showMoreSeizeDeatil('DriverName', scope.row)">
                    {{ scope.row.DriverName }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column prop="CarNumber" label="车牌号码" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <el-link class="clickTxt" @click="showMoreSeizeDeatil('CarNumber', scope.row)">
                    {{ scope.row.CarNumber }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column prop="LoadCount" label="运输数量" show-overflow-tooltip align="center">
                <template slot-scope="scope">{{ scope.row.LoadCount }} </template>
              </el-table-column>
              <el-table-column prop="CarrierPrice" label="单价" show-overflow-tooltip align="center">
                <template>
                  <span style="color: orange">{{
                    seizeDriverInfo.CarrierPrice | formatMoney
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="Money" label="运费" show-overflow-tooltip align="center">
                <template slot-scope="scope">
                  <span style="color: orange">{{ scope.row.Money | formatMoney }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="RealityLoadingStartTime" label="装货时间" align="center" width="180">
                <template slot-scope="scope">
                  {{ scope.row.RealityLoadingStartTime }}
                </template>
              </el-table-column>
              <el-table-column prop="RealityLoadingEndTime" label="卸货时间" show-overflow-tooltip align="center" width="180">
                <template slot-scope="scope">
                  <el-link class="clickTxt" @click="showMoreSeizeDeatil('RealityLoadingEndTime', scope.row)">
                    {{ scope.row.RealityLoadingEndTime }}
                  </el-link>
                </template>
              </el-table-column>
              <el-table-column fixed="right" align="center" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button type="text" size="small"
                    @click="detailOrder(currentOrderObj, scope.row.Index)">详情</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </div>
      </el-dialog>

      <!--货主发往运单库-->
      <el-dialog top="22vh" class="dialog" :visible.sync="flag.goWaybillDialog" width="960px"
        :close-on-click-modal="false">
        <span slot="title">选择业务场景</span>
        <el-form ref="goWaybillSceneForm" inline :model="goWaybillSceneForm" size="medium" label-width="140px">
          <el-form-item label="发票类型" prop="businessType">
            <el-select v-model="goWaybillSceneForm.businessType" placeholder="请选择发票类型" @change="businessChange" clearable>
              <el-option v-for="item in businessList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="场景类型" prop="sceneType">
            <el-select v-model="goWaybillSceneForm.sceneType" placeholder="请选择场景类型" clearable>
              <el-option v-for="item in sceneList" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" :icon="flag.toOrderLibrary ? 'el-icon-loading' : 'el-icon-s-promotion'"
              style="margin-left: 20px" @click="goWaybillLibrary">发往运单库</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!--支付方式-->
      <el-dialog top="22vh" class="dialog" :visible.sync="flag.paymentDialog" width="450px" :close-on-click-modal="false">
        <span slot="title">选择支付方式</span>
        <el-radio-group v-model="paymentType" style="margin-right: 20px">
          <el-radio label="0">线下支付</el-radio>
          <el-radio label="1">线上支付</el-radio>
        </el-radio-group>

        <el-button type="primary" @click="paymentOrder()" :loading="flag.paymentLoading">支付运费</el-button>

        <div class="allow-info" style="display: flex; margin-top: 20px" v-if="paymentType == '1'">
          <p>
            可交易金额：<span class="info-content" style="color: #ff8b17; font-weight: 600">{{ dealAmount | formatMoney
            }}</span>
          </p>

          <p style="margin-left: 50px">
            待支付总金额：<span class="info-content" style="color: #ff8b17; font-weight: 600">{{ paymentOrderRow.CarrierPrice |
              formatMoney }}</span>
          </p>
        </div>
      </el-dialog>

      <!-- 上传凭证dialog -->
      <el-dialog width="1200px" title="凭证" center :visible.sync="flag.evidenceDialog" append-to-body
        v-if="flag.evidenceDialog" :close-on-click-modal="false">
        <EvidenceInfo :businessID="evidenceOrderId"> </EvidenceInfo>
      </el-dialog>

      <!--编辑运费-->
      <el-dialog top="22vh" class="dialog" :visible.sync="flag.updateFreightDialog" width="340px"
        :close-on-click-modal="false">
        <span slot="title">编辑运费</span>
        <template>
          <el-input v-model="updateFreightVal" placeholder="请输入运费" type="text" style="width: 150px"
            onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')" maxlength="8"></el-input>
          <el-button type="primary" @click="updateFreight()" style="margin-left: 10px">提交</el-button>
        </template>
      </el-dialog>
      <!--打印运费-->
      <htwlPdfPrint ref="htwlPdfPrint"></htwlPdfPrint>
    </div>
  </div>
</template>

<script>
import {
  getOrderHtwList,
  GetOrderTimeAsync,
  PutOrderStatus,
  DeleteOrder,
  GoWaybillAsync,
  GetHtwOrdersFirst,
  PaymentOrder,
  UpdateFreight,
} from "@/api/contractLogistics/createPlan";

import {
  getDriverDetails,
  getCarInfo,
  getMapPath,
  getDataDict,
} from "@/api/common/common.js";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import htwlPdfPrint from "@/components/businessCmpt/htwlPdfPrint";
import TXmap from "@/components/commonCmpt/TXmap";
import { setOrderStatus } from "@/utils/setHTWLstatus";
import vehicleTrack from "@/components/commonCmpt/vehicleTrack";
import NP from "number-precision";
import { getTaskScene } from "@/api/goodsOwner/goodsOwnerManage/basicInfo";
import EvidenceInfo from "@/components/businessCmpt/evidenceInfo";
import { getYMAccount } from "@/api/finance/bank";
import { _getUserId } from "@/utils/storage";
export default {
  data() {
    return {
      flag: {
        activeTab: "1",
        personDialog: false, // 人员信息弹框
        vehicleDialog: false, // 车辆信息弹框
        peoAndGoodsDialog: false, // 人员、商品信息
        traceDialog: false, // 查看轨迹组件
        vehicleTrack: false, // 车辆轨迹
        toOrderLibrary: false, // 发往运单库
        loading: false, // 打开弹框
        seizeDriverDialog: false, // 抢单司机信息列表
        goWaybillDialog: false,
        paymentDialog: false,
        evidenceDialog: false,
        updateFreightDialog: false, //编辑运费
        paymentLoading: false,
      },
      // 表单查询
      supplierForm: {
        status: "全部", // ["新任务"]
        orderID: "", // 订单号
        goodsName: "", // 货品名称
        driverName: "", // 司机名称
        driverPhone: "", // 司机电话
        payeeName: "", // 收款人名称
        payeePhone: "", // 收款人电话
        loaderName: "", // 提货人名称
        loaderPhone: "", // 提货人电话
        signUserName: "", // 收货人名称
        signUserPhone: "", // 收货人电话
        startAdressRoad: "", // 起始地
        endAddressRoad: "", // 到达地
        loadingStartTime: [], // 装货开始时间
        loadingEndTime: [], // 装货结束时间
        startTime: [], // 起始时间
        endTime: [], // 到达时间
        createTime: [], // 创建时间
        facilitatorName: "", // 服务商名称
      },
      orderFrom: 1, // 订单类型 服务商承运1, 自主运输0, 市场抢单2
      // 分页
      pagination: {
        page: 1,
        pagesize: 10,
        total: 0,
        tableData: [], // 列表数据
        loading: false,
      },
      // 名字查询条件
      nameTypeList: [
        { name: "提货人姓名", value: "loaderName" },
        { name: "收货人姓名", value: "signUserName" },
        { name: "收款人姓名", value: "payeeName" },
        { name: "司机姓名", value: "driverName" },
      ],
      // 抢单 - 名字查询条件
      seizeNameTypeList: [
        { name: "提货人姓名", value: "loaderName" },
        { name: "收货人姓名", value: "signUserName" },
        { name: "收款人姓名", value: "payeeName" },
      ],
      nameSelect: "loaderName", // 选择的名字类型
      // 电话查询条件
      phoneTypeList: [
        { name: "提货人电话", value: "loaderPhone" },
        { name: "收货人电话", value: "signUserPhone" },
        { name: "收款人电话", value: "payeePhone" },
        { name: "司机电话", value: "driverPhone" },
      ],
      // 抢单 - 电话查询条件
      seizePhoneTypeList: [
        { name: "提货人电话", value: "loaderPhone" },
        { name: "收货人电话", value: "signUserPhone" },
        { name: "收款人电话", value: "payeePhone" },
      ],
      phoneSelect: "loaderPhone", // 选择的手机号类型
      // 地址查询条件
      addressTypeList: [
        { name: "起始地", value: "startAdressRoad" },
        { name: "到达地", value: "endAddressRoad" },
      ],
      addressSelect: "startAdressRoad", // 选择的地址类型
      // 时间查询条件
      timeTypeList: [
        { name: "创建时间", value: "createTime" },
        { name: "装货开始时间", value: "loadingStartTime" },
        { name: "装货结束时间", value: "loadingEndTime" },
        { name: "起始时间", value: "startTime" },
        { name: "到达时间", value: "endTime" },
      ],
      timeSelect: "createTime", // 选择的时间类型
      timeSelectTxt: "创建时间", // 选择的时间类型名称
      multipleSelection: [],
      showSelect: false, // 控制是否显示筛选框
      orderStatusList: [
        { name: "全部", value: 0, code: [] },
        { name: "待确定", value: 1, code: [2, 3] },
        { name: "已接受", value: 2, code: [4, 7] },
        { name: "待运输", value: 3, code: [10] },
        { name: "已拒绝", value: 4, code: [5, 8] },
        { name: "已取消", value: 5, code: [1, 9] },
        { name: "运输中", value: 6, code: [11] },
        { name: "已完成", value: 7, code: [12] },
        { name: "已支付", value: 8, code: [15] },
        { name: "已发往运单库", value: 9, code: [13] },
      ],
      seizeOrderStatusList: [
        { name: "全部", value: 0, code: [] },
        { name: "市场", value: 1, code: [14] },
        { name: "运输中", value: 2, code: [11] },
        { name: "已完成", value: 3, code: [12] },
        { name: "已发往运单库", value: 4, code: [13] },
      ],
      driverInfo: {}, // 司机 收款人信息
      vehicleInfo: {}, // 车辆信息
      otherDetail: "", // 详情类型
      // 更多时间
      otherTimeList: [
        { name: "LoadingStartTime", text: "装货开始时间" },
        { name: "LoadingEndTime", text: "装货结束时间" },
        { name: "StartTime", text: "起始时间" },
        { name: "EndTime", text: "到达时间" },
        { name: "CreateTime", text: "创建时间" },
        { name: "StatusChangeTime", text: "状态变更时间" },
      ],
      // 更多人员
      otherPeoList: [
        { name: "PayeeName", text: "收款人" },
        { name: "PayeePhone", text: "手机号" },
        { name: "LoaderName", text: "提货人" },
        { name: "LoaderPhone", text: "手机号" },
        { name: "SignUserName", text: "收货人" },
        { name: "SignUserPhone", text: "手机号" },
      ],
      // 更多商品信息
      otherGoodsList: [
        { name: "GoodsName", text: "货品名称" },
        { name: "LoadCount", text: "货品数量" },
        { name: "GoodsUnit", text: "货品单位" },
        { name: "GoodsTotalWeight", text: "货品总重", Company: "吨" },
        { name: "GoodsValue", text: "货品价值", Company: "元" },
        { name: "CarrierPrice", text: "运费", Company: "元" },
      ],
      currentOrder: "", // 查看详情,当前选中的订单单号
      currentOrderObj: {}, // 查看详情,当前选中的订单信息
      seizeDriverInfo: {
        driverOrders: [],
        upAddress: "",
        unAddress: "",
      }, // 抢单司机列表

      goWaybillSceneForm: {
        businessType: "", //业务类型
        sceneType: "", //场景类型
      },
      sceneList: [], //场景类型
      businessList: [], //业务类型
      paymentType: "1",
      paymentOrderRow: "", //支付运费的订单号
      evidenceOrderId: "", //凭证订单号
      updateFreightOrderId: "", //编辑运费订单号
      updateFreightVal: 0, //编辑运费订单
      dealAmount: 0, //可交易金额
    };
  },
  created() {
    let { type } = this.$route.query;
    if (type) (this.flag.activeTab = type), (this.orderFrom = type);

    this.getOrderHtwList(this.orderFrom);

    //获取业务类型
    getDataDict({ type: 27 }).then((res) => {
      this.businessList = res.patterSetInfo;
    });
  },
  components: {
    htwlPdfPrint,
    DriverInfo,
    VehicleInfo,
    TXmap,
    vehicleTrack,
    EvidenceInfo,
  },
  methods: {
    // 隐藏/显示筛选框
    isShowSelect() {
      this.showSelect = !this.showSelect;
    },

    // 设置未完成的订单不能勾选
    selectable(row, index) {
      let status = 12;
      if (this.flag.activeTab == 0) {
        status = 15;
      }
      if (row.Status != status) {
        return false;
      } else {
        return true;
      }
    },

    // 切换tab页
    changeTabs(tab) {
      this.pagination.page = 1;
      this.orderFrom = tab.name;
      this.clearSearch();

      this.$set(this.otherGoodsList.at(-1), "text", tab.name != 2 ? "运费" : "单价");
    },

    // 设置运单状态
    setOrderStatus,

    // 获取合同物流列表
    getOrderHtwList(flag) {
      this.orderFrom = flag;
      this.pagination.loading = true;

      let params = {
        ...this.supplierForm,
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
      };

      params.status = this.chooseStatus(this.supplierForm.status)
        ? this.chooseStatus(this.supplierForm.status)
        : [];

      getOrderHtwList(flag, params)
        .then((res) => {
          let { TotalCount, DataList } = res.data;
          this.pagination.total = TotalCount;
          this.pagination.tableData = DataList.map((item, index) => {
            item.index =
              (this.pagination.page - 1) * this.pagination.pagesize + index + 1;
            item.visible = false;
            return item;
          });

          this.pagination.loading = false;
        })
        .finally(() => {
          this.pagination.loading = false;
        });
    },

    // 分页
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.getOrderHtwList(this.orderFrom);
    },

    //分页数量改变
    sizeChange(e) {
      this.pagination.pagesize = e;
      this.getOrderHtwList(this.orderFrom);
    },

    // 清空查询条件
    clearSearch() {
      (this.supplierForm = {
        status: "全部", // ["新任务"]
        orderID: "", // 订单号
        goodsName: "", // 货品名称
        driverName: "", // 司机名称
        driverPhone: "", // 司机电话
        payeeName: "", // 收款人名称
        payeePhone: "", // 收款人电话
        loaderName: "", // 提货人名称
        loaderPhone: "", // 提货人电话
        signUserName: "", // 收货人名称
        signUserPhone: "", // 收货人电话
        startAdressRoad: "", // 起始地
        endAddressRoad: "", // 到达地
        loadingStartTime: [], // 装货开始时间
        loadingEndTime: [], // 装货结束时间
        startTime: [], // 起始时间
        endTime: [], // 到达时间
        createTime: [], // 创建时间
        facilitatorName: "", // 服务商名称
      }),
        (this.nameSelect = "loaderName"), // 选择的名字类型
        (this.phoneSelect = "loaderPhone"), // 选择的手机号类型
        (this.addressSelect = "startAdressRoad"), // 选择的手机号类型
        (this.timeSelect = "createTime"), // 选择的时间类型
        (this.timeSelectTxt = "创建时间"), // 选择的时间类型名称
        this.getOrderHtwList(this.orderFrom);
    },

    // el-table设置分页多选
    getRowKeys(row) {
      return row.index;
    },

    // 多选框存储值
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // 发往运单库
    toOrderLibrary() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning("请选择需要发往运单库的运单!");
        return;
      }
      if (this.flag.activeTab == 0) {
        this.flag.goWaybillDialog = true;
      } else {
        this.flag.toOrderLibrary = true;
        let params = {
          TaskSceneTypeId: 1,
          OrderIds: [],
        };
        this.multipleSelection.forEach((item) => {
          params.OrderIds.push(item.OrderID);
        });

        GoWaybillAsync(params)
          .then((res) => {
            this.$message({
              message: `发往运单库成功,生成单号：${res.data}`,
              type: "success",
              duration: 6000,
            });
            this.$refs.multipleTable.clearSelection();
            this.getOrderHtwList(this.orderFrom);
          })
          .finally(() => {
            this.flag.toOrderLibrary = false;
          });
      }
    },

    // 发往运单库
    goWaybillLibrary() {
      if (!this.goWaybillSceneForm.sceneType) {
        this.$message.warning("请选择场景类型!");
        return;
      }

      this.flag.toOrderLibrary = true;
      let params = {
        TaskSceneTypeId: this.goWaybillSceneForm.sceneType,
        OrderIds: [],
      };
      this.multipleSelection.forEach((item) => {
        params.OrderIds.push(item.OrderID);
      });

      GoWaybillAsync(params)
        .then((res) => {
          this.$message({
            message: `发往运单库成功,生成单号：${res.data}`,
            type: "success",
            duration: 6000,
          });
          this.$refs.multipleTable.clearSelection();
          this.getOrderHtwList(this.orderFrom);
        })
        .finally(() => {
          this.flag.toOrderLibrary = false;
          this.flag.goWaybillDialog = false;
        });
    },

    // 取消订单
    cancelOrder(orderID) {
      this.$prompt(
        "取消计划前请与司机进行沟通，以避免双方造成 不必须要的损失和误会",
        `取消计划提示`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
          inputPlaceholder: "请输入取消原因",
          // center: true,
          inputValidator(value) {
            if (!value) return "请输入取消原因";
          },
        }
      )
        .then((e) => {
          PutOrderStatus({
            orderId: orderID,
            htwLorderStatusId: "货主已取消",
            msg: e.value,
          }).then((res) => {
            this.$message.success(`取消成功`);
            this.getOrderHtwList(this.orderFrom);
          });
        })
        .catch(() => { });
    },

    // 查询人员信息
    getPersonInfo(item,UserID) {
      if (!UserID) return;
      this.flag.loading = true;
      let data = {};
      getDriverDetails({ UserID, AscriptionUserID: item.OwnerUserId })
        .then((res) => {
          data = Object.assign({}, res.data.driver_baseInfo, res.data.driver_confirmInfo);
          this.driverInfo = data;
          this.flag.personDialog = true;
          this.flag.loading = false;
        })
        .finally(() => {
          this.flag.loading = false;
        });
    },

    // 获取车辆信息
    showVehicleInfo(CarNumber) {
      if (CarNumber.includes("指派")) return;
      this.flag.loading = true;
      let getCarInfoed = false; //是否拿到了车子证件照
      let params = {}; // 车辆信息
      let params2 = {
        CarNumber,
      };
      getCarInfo(params2)
        .then((res) => {
          this.vehicleInfo = Object.assign(
            params,
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          getCarInfoed = true;
          this.flag.loading = false;
        })
        .catch((err) => { })
        .finally(() => {
          if (getCarInfoed) {
            this.flag.vehicleDialog = true;
          }
          this.flag.loading = false;
        });
    },

    // 打开地图
    openMap(item, flag) {
      // flag 有值表示抢单
      console.log("item", item);
      if (!flag) {
        if (item.Status < 11 || item.Status == 14) {
          this.$message.info("该订单未运输，暂无轨迹");
          return;
        }
      }

      if (!item.RealityLoadingEndTime || !item.RealityLoadingStartTime) {
        this.$message.info("该订单无装/卸货时间，暂无法查询轨迹");
        return;
      }
      this.flag.loading = true;
      let params = {
        searchWord: item.CarNumber,
        stDate: item.RealityLoadingStartTime,
        edDate: item.RealityLoadingEndTime,
        coordType: 2,
        wayBillId: !flag ? item.OrderID : item.CardRecord[0].RecordID,
      };
      getMapPath({ json: JSON.stringify(params) })
        .then((res) => {
          this.mapList = res.Track;
          this.flag.traceDialog = true;
        })
        .finally(() => {
          this.flag.loading = false;
        });
    },

    // 删除订单
    deleteOrder(orderID) {
      this.$confirm("是否删除该订单,删除后无法被找回,是否继续？", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: "error",
        center: true,
        cancelButtonText: "取消",
      })
        .then(() => {
          DeleteOrder(orderID).then((res) => {
            this.$message.success(`删除成功`);
            this.getOrderHtwList(this.orderFrom);
          });
        })
        .catch(() => { });
    },

    // 查看运单详情
    detailOrder(order, driverIdx) {
      this.currentOrder = order.OrderID;

      GetHtwOrdersFirst(order.OrderID).then((res) => {
        this.currentOrderObj = res.data || {};
      });

      this.flag.vehicleTrack = true;

      this.$nextTick(() => {
        this.$refs.vehicleTrack.loading = true;
        this.flag.activeTab != 2
          ? this.$refs.vehicleTrack.getClockInList()
          : (this.$refs.vehicleTrack.seizeDriverIndex = driverIdx); // 获取打卡记录

        this.$refs.vehicleTrack.openMap(order); //打开地图
      });
    },

    // 选择运单状态
    chooseStatus(e) {
      let list =
        this.flag.activeTab != 2 ? this.orderStatusList : this.seizeOrderStatusList;
      let res = list.find((item) => item.value == e);
      if (res) return res.code;
    },

    // 选择查询名字、电话、 地址 类型
    chooseType(e, list, selectKey) {
      list.forEach((item) => {
        this.supplierForm[item.value] = "";
      });

      this[selectKey] = e;
    },

    // 选择时间下拉菜单
    handleCommand(e) {
      this.timeTypeList.forEach((item) => {
        this.supplierForm[item.value] = [];
      });

      this.timeSelectTxt = e.name;
      this.timeSelect = e.value;
    },

    // 清除时间, 设置为空
    changeTime(e) {
      if (e == null) {
        this.$nextTick(() => {
          this.supplierForm[this.timeSelect] = [];
        });
      }
    },

    // 显示更多时间
    orderMoreTime(id, type) {
      this.otherDetail = type;
      GetOrderTimeAsync(id, type).then((res) => {
        let { data } = res;
        let list =
          type == "time"
            ? "otherTimeList"
            : type == "goods"
              ? "otherGoodsList"
              : "otherPeoList";

        this[list].forEach((item) => {
          item.time = data[item.name];
        });

        this.flag.peoAndGoodsDialog = true;
      });
    },

    // 创建计划, 取消计划, 重选服务商， 重选司机
    createOrder(orderId, reselect = false, upDriver = false) {
      this.$router.push({
        path: "/contractLogistics/createPlan",
        query: {
          type: this.flag.activeTab,
          orderId,
          reselect,
          upDriver,
        },
      });
    },

    // 获取订单详情信息
    showSeizeDriverInfo(order) {
      this.flag.loading = true;
      this.currentOrder = order.OrderID;
      GetHtwOrdersFirst(order.OrderID)
        .then((res) => {
          let {
            DriverOrders = [],
            CarrierPrice = 0,
            UpDetailedAddress = "",
            UnDetailedAddress = "",
            StartDetailedAddress = "",
            EndDetailedAddress = "",
          } = res.data || {};
          this.currentOrderObj = res.data || {};

          this.seizeDriverInfo = {
            CarrierPrice: CarrierPrice,
            driverOrders: DriverOrders,
            upAddress: UpDetailedAddress,
            unAddress: UnDetailedAddress,
            startDetailedAddress: StartDetailedAddress,
            endDetailedAddress: EndDetailedAddress,
          };

          this.flag.seizeDriverDialog = true;
        })
        .finally(() => {
          this.flag.loading = false;
        });
    },

    // 接单司机信息, 打开对应的详情弹框
    showMoreSeizeDeatil(str, data) {
      this.flag.seizeDriverDialog = false;
      switch (str) {
        case "DriverName":
          this.getPersonInfo(data,data.DriverId);
          break;
        case "CarNumber":
          this.showVehicleInfo(data.CarNumber);
          break;
        case "RealityLoadingEndTime":
          this.openMap(
            {
              ...data,
              OrderID: this.currentOrder,
            },
            true
          );
          break;
      }
    },

    // 总数 - 已接单数 = 剩余数量
    residueNum(LoadCount, TranNum) {
      return NP.minus(LoadCount, TranNum) || 0;
    },

    //支付运费
    paymentOrder() {
      let orderId = this.paymentOrderRow.OrderID;
      let IsOnline = this.paymentType == "0" ? false : true;

      if (this.dealAmount < this.paymentOrderRow.CarrierPrice && IsOnline) {
        this.$message.error(`余额不足`);
        return;
      }
      this.$confirm("是否支付订单" + orderId + "的运费？", "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: "success",
        center: true,
        cancelButtonText: "取消",
      }).then(() => {
        this.flag.paymentLoading = true;
        PaymentOrder({
          orderId,
          IsOnline,
        })
          .then((res) => {
            this.$message.success(`支付成功`);
            this.getOrderHtwList(this.orderFrom);
            this.flag.paymentLoading = false;
            this.flag.paymentDialog = false;
          })
          .finally(() => {
            this.flag.paymentLoading = false;
          });
      });
    },
    //业务类型改变
    businessChange(e) {
      this.goWaybillSceneForm.sceneType = "";
      getTaskScene({ InvoiceType: e }).then((res) => {
        this.sceneList = res.TaskTypeList.map((item) => {
          return {
            Code: item.TaskSceneTypeID,
            Name: item.TaskSceneName,
          };
        });
      });
    },

    //打开支付运费弹窗
    openPaymentDialog(row) {
      getYMAccount({
        userid: parseInt(_getUserId()) / 5,
      }).then((res) => {
        if (res.data) {
          this.dealAmount = res.data.DealAmount;
        }
      });
      this.paymentType = "1";
      this.paymentOrderRow = row;
      this.flag.paymentDialog = true;
    },

    //打开凭证弹窗
    openEvidenceDialog(orderId) {
      this.evidenceOrderId = orderId;
      this.flag.evidenceDialog = true;
    },

    //打开编辑运费弹窗
    openUpdateFreightDialog(row) {
      this.updateFreightOrderId = row.OrderID;
      this.updateFreightVal = row.CarrierPrice;
      this.flag.updateFreightDialog = true;
    },

    //修改运费
    updateFreight() {
      UpdateFreight({
        orderId: this.updateFreightOrderId,
        Freight: this.updateFreightVal,
      }).then((res) => {
        this.getOrderHtwList(this.orderFrom);
        this.flag.updateFreightDialog = false;
      });
    },
  },
  computed: {
    // 更多详情列表
    getOtherList() {
      const type = {
        time: this.otherTimeList,
        goods: this.otherGoodsList,
        name: this.otherPeoList,
      };
      return type[this.otherDetail];
    },
  },
};
</script>

<style lang="scss" scoped>
.clickTxt {
  color: #0f5fff;
}

.linkActive:hover {
  color: #606266 !important; //高亮的颜色
  cursor: default;
}

.detail-msg {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px !important;

  div {
    width: 48%;
    line-height: 40px;
  }
}

.seize-detail {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px !important;

  .seize-detail-item {
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    align-items: center;

    &:nth-last-child(1) {
      border-bottom: none;
    }
  }
}

.contractWaybillPage .inputWidth {
  width: 300px !important;

  /deep/ .el-input__inner {
    height: 42px !important;
  }
}

.contractWaybillPage .seizeDriverInput {
  width: 240px !important;
}

.allow-info {
  p {
    // width: 40%;
    margin: 5px 0;
    text-align: left;
    display: flex;

    // &:nth-child(1) {
    //   width: 60%;
    // }

    .info-title {
      font-weight: bolder;
      width: 36%;
      text-align: right;
      white-space: nowrap;
    }

    .info-content {
      color: #333;
    }

    &:nth-child(2) {
      .info-title {
        width: 64%;
      }
    }
  }
}
</style>

<style>
.customClass .el-dialog__header,
.customClass .el-dialog__body {
  padding: 0 !important;
}

.customClass .el-dialog__headerbtn {
  z-index: 10000;
  top: 10px;
  right: 25px;
}

.peoAndGoodsDialog .el-dialog__body {
  padding: 0 20px 30px;
}

.peoAndGoodsDialog .el-form--label-top .el-form-item__label {
  padding: 0;
}
</style>
